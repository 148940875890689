import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';
const InterestAreaScreen = lazy(() =>
  import('../../Components/Pages/InteresAreaScreen/InterestAreaScreen')
);

const TrainingsScreen = lazy(() =>
  import('../../Components/Pages/TrainingsScreen/TrainingsScreen')
);

const CourseInfoScreen = lazy(() =>
  import('../../Components/Pages/CourseInfoScreen/CourseInfoScreen')
);

export const RoutesUniversity = [
  {
    path: '/trainings',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/trainings',
    exact: true,
    component: TrainingsScreen
  },
  {
    path: '/interest-area',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/interest-area',
    exact: true,
    component: InterestAreaScreen
  },
  {
    path: '/course-info',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/course-info',
    exact: true,
    component: CourseInfoScreen
  }
];
