import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';
const EventsScreen = lazy(() => import('../../Components/Pages/EventsScreen/EventsScreen'));

export const RoutesEvents = [
  {
    path: '/next-events',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/next-events',
    exact: true,
    component: EventsScreen
  }
];
