import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';
const ServicesScreen = lazy(() => import('../../Components/Pages/ServicesScreen/ServicesScreen'));

export const RoutesServices = [
  {
    path: '/services',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/services',
    exact: true,
    component: ServicesScreen
  }
];
