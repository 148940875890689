import React, { useEffect, useRef, useState } from 'react';

// import "../styles/FormContainerStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getNavbarHeight } from '../../../Core/Hooks/HooksGenerals/UseMediaQuery';
import { useGetParameters } from '../../../Core/Hooks/HooksParameters/useGetParameters';
import CenteredSpinner from '../CenteredSpinner/CenteredSpinner';
import InputTextSKT from '../../Skeletons/InputText/InputTextSKT';

// Google Search Form

// array with the urls of the pages that can't have a background of 100vh
const blackList = ['/services', '/next-events', '/jobs', '/job'];

const GoogleSearchForm = (props) => {
  const { q: searchText, containerClassName, clickOutsideSearchForm = () => {} } = props;

  const isInBlackList = blackList.includes(window.location.pathname);

  const [parameters] = useGetParameters(['INF_MSG_36', 'INF_MSG_37']);
  const [backgroundColor, setBackgroundColor] = useState(
    window.scrollY > 100 || isInBlackList ? '#000' : '#fff'
  );

  const siteSearchRef = useRef(null);
  const [aditionalStyle, setAditionalStyle] = useState(
    window.scrollY > 100 || isInBlackList
      ? {
          top: `${getNavbarHeight()}px`,
          transform: 'translate(-50%, 0)',
          backgroundColor: '#fff'
        }
      : { backgroundColor: 'transparent' }
  );
  const [titleStyle, setTitleStyle] = useState(
    window.scrollY > 100 || isInBlackList ? { color: '#000' } : { color: '#fff' }
  );

  const updateStyles = () => {
    if (window.scrollY > 100 || isInBlackList) {
      setAditionalStyle({
        top: `${getNavbarHeight()}px`,
        transform: 'translate(-50%, 0)',
        backgroundColor: '#fff'
      });
      setTitleStyle({ color: '#000' });
      setBackgroundColor('#fff');
    } else {
      setTitleStyle({ color: '#fff' });
      setAditionalStyle({ backgroundColor: 'transparent' });
      setBackgroundColor('#000');
    }
  };
  useEffect(() => {
    const handleClickOutsideNavbar = (event) => {
      // @see https://stackoverflow.com/a/55473061
      // @see https://stackoverflow.com/a/64136363
      // @see https://github.com/facebook/react/issues/20325#issuecomment-732707240
      if (!siteSearchRef?.current?.contains(event.target)) {
        clickOutsideSearchForm();
      }
    };
    document.addEventListener('scroll', updateStyles);
    document.addEventListener('click', handleClickOutsideNavbar, {
      capture: true
    });
    return () => {
      document.removeEventListener('click', handleClickOutsideNavbar, {
        capture: true
      });
      document.removeEventListener('scroll', updateStyles);
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cse.google.com/cse.js?cx=4ef2b2a2e29887cd6';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className={classNames('site-search-container', containerClassName || 'color-black')}
      style={{ boxShadow: '0 0px 10px 0px grey' }}>
      <div ref={siteSearchRef} className="site-search" style={aditionalStyle}>
        <div className="container d-flex flex-column" style={{ backgroundColor: 'transparent' }}>
          {parameters?.INF_MSG_36 ? (
            <h3
              style={!searchText ? titleStyle : {}}
              className="text-center legend mb-3 mt-3"
              // * parameter code: INF_MSG_36
            >
              {parameters?.INF_MSG_36?.value}
            </h3>
          ) : (
            <CenteredSpinner backgroundColor={backgroundColor} />
          )}

          <form method="get" title="Search Form" action="/search" role="search">
            <div className="row align-items-center justify-content-center">
              <div className="col-10 col-md-5 p-0">
                {parameters?.INF_MSG_37 ? (
                  <Form.Control
                    type="text"
                    name="q"
                    title="Search this site"
                    // * parameter code: INF_MSG_37
                    placeholder={parameters?.INF_MSG_37?.value}
                    alt="Search text"
                    maxLength="256"
                    autoComplete="off"
                    defaultValue={searchText}
                    autoFocus={true}
                  />
                ) : (
                  <InputTextSKT />
                )}
              </div>
              <div className="col-10 col-md-2 search-button-container ms-md-3 mt-3 mt-md-0 p-0">
                <button
                  aria-label="Search button using Google API"
                  className="search-button d-flex justify-content-center align-items-center w-100">
                  <FontAwesomeIcon
                    className="search-icon"
                    aria-label="Search icon"
                    icon={faSearch}
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GoogleSearchForm;
