import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMobileMenuStyle } from '../../../Domain/Redux/Actions/Actions/ActionsMobileMenu';
import { useMediaQuery } from '../../../Core/Hooks/HooksGenerals/UseMediaQuery';

import './MobileMenuOverlay.css';

const MobileMenuOverlay = () => {
  const [width] = useMediaQuery();
  const isMenuOpen = useSelector((store) => store.mobileMenu.mobileMenuOpened);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setMobileMenuStyle(!isMenuOpen));
  };

  return (
    <>
      {width <= 962 && (
        <div
          className={`${isMenuOpen ? 'mobileMenuOpen' : 'mobileMenuClosed'}`}
          onClick={handleClick}></div>
      )}
    </>
  );
};

export default MobileMenuOverlay;
