import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';
const TISolutionsScreen = lazy(() =>
  import('../../Components/Pages/TISolutionsScreen/TISolutionsScreen')
);

const TIServiceSreen = lazy(() => import('../../Components/Pages/TIServiceSreen/TIServiceSreen'));

export const RoutesSoftware = [
  {
    path: '/ti-solutions',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/ti-solutions',
    exact: true,
    component: TISolutionsScreen
  },
  {
    path: '/ti-services',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/ti-services',
    exact: true,
    component: TIServiceSreen
  }
];
