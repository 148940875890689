import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';
const LiteThinkingScreen = lazy(() =>
  import('../../Components/Pages/LiteThinkingScreen/LiteThinkingScreen')
);
const PoliciesOfUsage = lazy(() =>
  import('../../Components/Pages/PoliciesOfUsage/PoliciesOfUsage')
);
const PrivacityPolicies = lazy(() =>
  import('../../Components/Pages/PrivacityPolicies/PrivacityPolicies')
);

const SearchResultScreen = lazy(() =>
  import('../../Components/Pages/SearchResultScreen/SearchResultScreen')
);

const PreRegisterScreen = lazy(() =>
  import('../../Components/Pages/PreRegisterScreen/PreRegisterScreen')
);

const NewsViewer = lazy(() => import('../../Components/Pages/NewsViewer/NewsViewer'));

export const RoutesLiteThinking = [
  {
    path: '/lite-thinking',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/lite-thinking',
    exact: true,
    component: LiteThinkingScreen
  },
  {
    path: '/policies-of-usage',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/policies-of-usage',
    exact: true,
    component: PoliciesOfUsage
  },
  {
    path: '/privacity-policies',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/privacity-policies',
    exact: true,
    component: PrivacityPolicies
  },
  {
    path: '/search',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/search',
    exact: true,
    component: SearchResultScreen
  },
  {
    path: '/register',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/register',
    exact: true,
    component: PreRegisterScreen
  },
  {
    path: '/news',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/news',
    exact: true,
    component: NewsViewer
  }
];
