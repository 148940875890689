/*
 * this file save the corresponding name of the differents
 * (screens / pages) used for grouping the components and make just a single request
 */

export const COMPONENTS = {
  FOOTER: 'Footer',
  LOGO: 'Logo',
  MODAL_LITE_T: 'ModalLiteT',
  NAVIGATION_BAR: 'NavigationBar',
  PREVIEW_MODAL: 'PreviewModal',
  CHANGE_PASSWORD_SCREEN: 'ChangePasswordScreen',
  COURSES_SCREEN: 'CoursesScreen',
  ENGLISH_TEST_RESULT_SCREEN: 'EnglishTestResultScreen',
  ENGLISH_TEST_SCREEN: 'EnglishTestScreen',
  HOME_SCREEN: 'HomeScreen',
  JOB_SCREEN: 'JobScreen',
  JOBS_SCREEN: 'JobsScreen',
  LITE_THINKING_SCREEN: 'LiteThinkingScreen',
  NEXT_EVENT_SCREEN: 'NextEventScreen',
  PRE_REGISTER_SCREEN: 'PreRegisterScreen',
  SERVICES_SCREEN: 'ServicesScreen',
  CONSULTING_SERVICE_SCREEN: 'ConsultingServiceScreen',
  COURSE_INFO_SCREEN: 'CourseInfoScreen',
  INTERES_AREA_SCREEN: 'InteresAreaScreen',
  ORGANIZATIONAL_CONSULTING_SCREEN: 'OrganizationalConsultingScreen',
  TI_SERVICE_SCREEN: 'TIServiceScreen',
  TI_SOLUTIONS_SCREEN: 'TISolutionsScreen',
  TRAININGS_SCREEN: 'TrainingsScreen',
  CREDENTIALS_HOME_SECTION: 'CredentialsHomeSection',
  JOBS_HOME_SECTION: 'JobsHomeSection',
  JOBS_SECTION: 'JobsSection',
  LMS_HOME_SECTION: 'LMSHomeSection',
  LT_IN_THE_WORLD_SECTION: 'LTInTheWorldSection',
  OUR_PARTNERS: 'OurPartners',
  OUR_PORTFOLIO_SECTION: 'OurPortfolioSection',
  TEST_ENGLISH_SECTION: 'TestEnglishSection',
  WHY_LT_SECTION: 'WhyLTSection',
  LT_NEWS_VIEWER: 'LT_NEWS_VIEWER'
};
