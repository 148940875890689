import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getParametersAsObject,
  getParametersSpanish
} from '../../../Domain/Utils/ApiUtilies/Parameters/Parameters';

export const useGetParameters = (parameterList = [], tobeArray = false) => {
  const [parameters, setParameters] = useState(tobeArray ? [] : {});
  const language = useSelector((store) => store?.languageTraslate?.language);

  useEffect(() => {
    getParametersSpanish(parameterList).then((parameters) => {
      setParameters(tobeArray ? parameters : getParametersAsObject(parameters));
    });
  }, [language]);

  return [parameters];
};
