import React from 'react';
import useLanguageList from '../../../Core/Hooks/HookInitialSetup/useLanguageList';
import useLanguageMonitor from '../../../Core/Hooks/HookInitialSetup/useLanguage';

const InitialSetup = ({ children }) => {
  useLanguageList();
  useLanguageMonitor();
  return children;
};

export default InitialSetup;
