import GoogleSearchForm from '../GoogleSearchForm/GoogleSearchForm';
import React from 'react';
import classNames from 'classnames';

const GoogleSearchWrapper = ({ showSearchWrapper, setShowSearchWrapper }) => {
  return (
    <>
      {showSearchWrapper ? (
        <div
          className={classNames('search-container', {
            show: showSearchWrapper,
            scrolled: showSearchWrapper
          })}>
          <div className="search-wrapper">
            <div className="search-overlay" />
            <GoogleSearchForm
              containerClassName="color-white"
              showSearchWrapper={showSearchWrapper}
              clickOutsideSearchForm={() => setShowSearchWrapper(false)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GoogleSearchWrapper;
