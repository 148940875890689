import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';

const JobScreen = lazy(() => import('../../Components/Pages/JobScreen/JobScreen'));
const JobsScreen = lazy(() => import('../../Components/Pages/JobsScreen/JobsScreen'));

export const RoutesJobs = [
  {
    exact: true,
    path: '/job',
    component: useLanguageRedirect
  },
  {
    exact: true,
    path: '/:language/job',
    component: JobScreen
  },
  {
    exact: true,
    path: '/jobs',
    component: useLanguageRedirect
  },
  {
    exact: true,
    path: '/:language/jobs',
    component: JobsScreen
  }
];
