import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HttpClient } from '../../../Domain/Utils/ApiUtilies/Fetch/Fetch';
import { setLanguageList } from '../../../Domain/Redux/Actions/Actions/ActionLenguageNavigator';
import { LANGUAGE_LIST_PATH } from '../../../Domain/Redux/Paths';

const getListLanguages = async () => {
  try {
    let response = await HttpClient.GET(LANGUAGE_LIST_PATH);
    return response.results;
  } catch (error) {
    console.debug('>>>> fetch list languages faild=', error);
  }
};

// TODO: Is this function needed? Developed by: Sebastian Gomez
// function clearUndefinedValuesFromLocalStorage() {
//   for (const key in localStorage) {
//     if (Object.hasOwnProperty.call(localStorage, key)) {
//       const element = localStorage[key];
//       if (element === 'undefined') {
//         localStorage.removeItem(key);
//       }
//     }
//   }
// }

const useLanguageList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLanguageList = async () => {
      try {
        const languageList = await getListLanguages();
        dispatch(setLanguageList(languageList));
      } catch (error) {
        console.debug('>>>> useLanguageList: error=', error);
      }
    };

    fetchLanguageList();
  }, []);

  //
  // useEffect(() => {
  //   clearUndefinedValuesFromLocalStorage();
  // }, [localStorage]);
};

export default useLanguageList;
