import { HttpClient } from '../../../Utils/ApiUtilies/Fetch/Fetch';
import { LANGUAGE_LIST_PATH } from '../../Paths';
import { SET_LANGUAGE, SET_LANGUAGE_LIST } from '../ActionTypes/ActionTypesLenguage';

// set language list in redux
export function setLanguage(language) {
  return {
    type: SET_LANGUAGE,
    payload: language
  };
}

// set language list in redux
export function setLanguageList() {
  return (dispatch) => {
    HttpClient.GET(LANGUAGE_LIST_PATH).then((response) =>
      dispatch({
        type: SET_LANGUAGE_LIST,
        payload: response?.results
      })
    );
  };
}
