import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';
const HomeScreen = lazy(() => import('../../Components/Pages/HomeScreen/HomeScreen'));
const EnglishTestScreen = lazy(() =>
  import('../../Components/Pages/EnglishTestScreem/EnglishTestScreen')
);
const EnglishTestResultScreen = lazy(() =>
  import('../../Components/Pages/EnglishTestResultScreen/EnglishTestResultScreen')
);

export const RoutesHome = [
  {
    path: '/home',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/home',
    exact: true,
    component: HomeScreen
  },
  {
    path: '/english-test',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/english-test',
    exact: true,
    component: EnglishTestScreen
  },
  {
    path: '/english-test-result',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/english-test-result',
    exact: true,
    component: EnglishTestResultScreen
  }
];
