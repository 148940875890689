import { useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useLanguageRedirect = () => {
  const history = useHistory();
  const firstRender = useRef(true);
  const language = useSelector((store) =>
    store?.languageTraslate?.language?.description?.toLowerCase()
  );
  const languageList = useSelector((store) => store?.languageTraslate?.languageList);

  useEffect(() => {
    if (languageList?.length < 1) {
      return;
    }
    let base = history.location.pathname.split('/');
    const selectedLanguage = base[1];
    base = base[base.length > 2 ? 2 : 1];
    const findLanguage = languageList?.filter(
      (info) => info?.description?.toLowerCase() === selectedLanguage
    );
    if (findLanguage?.length > 0 && firstRender.current) {
      firstRender.current = false;
      return;
    }
    const { search, hash } = history.location;
    history.replace(`/${language}/${base}${search}${hash}`);
  }, [language, languageList]);
};

export default useLanguageRedirect;
