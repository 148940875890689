import React, { useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Footer from '../Components/Organisms/Footer/Footer';
import GoogleSearchWrapper from '../Components/Molecules/GoogleSearchWrapper/GoogleSearchWrapper';
import Header from '../Components/Organisms/Header/Header';
import NavbarBackgroundHelper from '../Components/Atoms/NavbarBackgroundHelper/NavbarBackgroundHelper';
import { RoutesConsultancy } from './RoutesConsultancy/RoutesConsultancy';
import { RoutesHome } from './RoutesHome/RoutesHome';
import { RoutesEvents } from './RoutesEvents/RoutesEvents';
import { RoutesJobs } from './RoutesJobs/RoutesJobs';
import { RoutesLiteThinking } from './RoutesLiteThinking/RoutesLiteThinking';
import { RoutesRedirect } from './RoutesRedirect/RoutesRedirect';
import { RoutesServices } from './RoutesServices/RoutesServices';
import { RoutesUniversity } from './RoutesUniversity/RoutesUniversity';
import { RoutesSoftware } from './RoutesSoftware/RoutesSoftware';
import { Suspense } from 'react';
import { getNavbarHeight } from '../Core/Hooks/HooksGenerals/UseMediaQuery';
import { recaptchaSiteKey } from '../Domain/Settings/Keys/Keys';
import MobileMenuOverlay from '../Components/Atoms/MobileMenuOverlay/MobileMenuOverlay';

// * To verify that suspense in lazy is working, uncomment the following line:
// const FallBack = () => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         width: '100vw',
//         height: '100vh',
//         backgroundColor: 'ddd',
//         fontSize: '20rem',
//         color: 'peru'
//       }}>
//       {' '}
//       Loading...{' '}
//     </div>
//   );
// };

//Route component for mapping routes easily
const RouteComponent = (props) => {
  const { component: Component, componentProps, ...rest } = props;
  const { showDivBgNavbar = true } = componentProps;
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {showDivBgNavbar && <div id="bgNavbar" style={{ height: `${getNavbarHeight()}px` }} />}
          <Component {...props} {...componentProps} />
        </>
      )}
    />
  );
};

const APP_ROUTES = [
  ...RoutesConsultancy,
  ...RoutesUniversity,
  ...RoutesLiteThinking,
  ...RoutesHome,
  ...RoutesServices,
  ...RoutesJobs,
  ...RoutesEvents,
  ...RoutesSoftware
];

const APP_ROUTES_REDIRECT = [...RoutesRedirect];

export const Routes = () => {
  const [showSearchWrapper, setShowSearchWrapper] = useState(false);

  const handleLoadedCaptcha = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(recaptchaSiteKey, { action: 'homepage' }).then((token) => {
        localStorage.setItem('reCaptcha', token);
      });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
    script.addEventListener('load', handleLoadedCaptcha);
    document.body.appendChild(script);
  }, []);

  return (
    <Router>
      <Suspense fallback={<></>}>
        <NavbarBackgroundHelper />

        <Header
          showSearchWrapper={showSearchWrapper}
          setShowSearchWrapper={setShowSearchWrapper}
          isBgTransparent={false}
        />
        <MobileMenuOverlay />

        <div
          id="contentWrapper"
          style={{
            minHeight: window.innerHeight - getNavbarHeight()
          }}>
          <GoogleSearchWrapper
            showSearchWrapper={showSearchWrapper}
            setShowSearchWrapper={setShowSearchWrapper}
          />
          <Switch>
            {APP_ROUTES_REDIRECT.map((props, index) => (
              <Redirect exact key={index} {...props} />
            ))}
            {APP_ROUTES.map((props, index) => (
              <Route key={index} {...props} />
            ))}
          </Switch>
        </div>
        <Footer />
        {/* <div className="g-recaptcha" data-sitekey={recaptchaSiteKey} data-size="invisible" /> */}
      </Suspense>
    </Router>
  );
};
