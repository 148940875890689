import Spinner from '../../Atoms/Spinner/Spinner';

const ParagraphWithLoading = ({ parameter, className, spinnerBg }) => {
  return parameter ? (
    <p className={`${className}`}>{parameter?.value}</p>
  ) : (
    <Spinner backgroundColor={spinnerBg} />
  );
};

export default ParagraphWithLoading;
