import { lazy } from 'react';
import useLanguageRedirect from '../../Core/Hooks/HookUrlRedirect/useLanguageRedirect';
const ConsultingServiceScreen = lazy(() =>
  import('../../Components/Pages/ConsultingServiceScreen/ConsultingServiceScreen')
);

const OrganizationalConsultingScreen = lazy(() =>
  import('../../Components/Pages/OrganizationalConsultingScreen/OrganizationalConsultingScreen')
);

export const RoutesConsultancy = [
  {
    path: '/consulting-services',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/consulting-services',
    exact: true,
    component: ConsultingServiceScreen
  },
  {
    path: '/organizational-consulting',
    exact: true,
    component: useLanguageRedirect
  },
  {
    path: '/:language/organizational-consulting',
    exact: true,
    component: OrganizationalConsultingScreen
  }
];
