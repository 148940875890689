import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNavbarHeight, useMediaQuery } from '../../../Core/Hooks/HooksGenerals/UseMediaQuery';

// Navbar separator

const NavbarBackgroundHelper = () => {
  const [height, setHeight] = useState(0);

  const width = useMediaQuery();

  useEffect(() => {
    setHeight(getNavbarHeight());
  }, [width]);

  // get the transparent state from redux
  const transparentBackground = useSelector((store) => store.stylesState.transparentBackground);
  // if the screen needs that the separator does not appear the atom return null instead the separator
  return transparentBackground ? null : <div id="bgNavbar" style={{ height: `${height}px` }} />;
};

export default NavbarBackgroundHelper;
