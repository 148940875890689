import React, { useLayoutEffect, useState } from 'react';
import { BACKEND_URL } from '../../../Domain/Redux/Paths';

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const bandA = a.price_col;
  const bandB = b.price_col;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export const getQueryParam = (searchLocation, searchParam) => {
  if (searchLocation.includes(searchParam)) {
    const location = searchLocation.split(`${searchParam}=`)[1];
    return location.split('&')[0];
  } else return 0;
};

export const getPaymentData = (
  item,
  mode,
  index,
  offset,
  userData,
  idCourse,
  currency,
  country
) => {
  return {
    // Parametros compra (obligatorio)
    name: item.name,
    description: item.name + ' - ' + mode.description + ' - ' + mode.descriptionCert,
    currency,
    amount: currency === 'usd' ? mode.price_usd : mode.price_col,
    country,
    lang: 'es',
    external: 'true',
    extra1: mode.pk,
    extra2: userData.pk,
    extra3: idCourse,
    response: `${BACKEND_URL}${REGISTER_BUY_PATH}?id=${index}&offset=${offset}  `,
    name_billing: userData.first_name + ' ' + userData.last_name,
    email_billing: userData.email
  };
};

export const useMediaQuery = () => {
  const [screenSize, setScreenSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateScreenSize() {
      setScreenSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateScreenSize);
    updateScreenSize();
    return () => window.removeEventListener('resize', updateScreenSize);
  }, []);

  return screenSize;
};

export const priceCalculator = (courseModeSet) => {
  let prices = [];
  if (courseModeSet && courseModeSet.length > 0) {
    prices = courseModeSet.map((mode) => {
      const duration = mode.duration;
      const defaultMode = {
        price_col: mode.price_col,
        price_usd: mode.price_usd,
        duration: `${duration}`,
        descriptionCert: mode.description,
        description: mode.mode.value,
        consultant: mode.consultant,
        pk: mode.pk
      };
      if (mode.scheduler_set && mode.scheduler_set.length > 0) {
        defaultMode.rangoTiempo = (
          <h6>
            <b>Fecha inicio: </b>
            <br />
            {mode.scheduler_set[0].start_date}
            <br /> <br />
            <b>Fecha fin: </b>
            <br />
            {mode.scheduler_set[0].end_date}
            <br />
            <br />
            {mode.scheduler_set[0].description}
          </h6>
        );
      }
      if (parseFloat(mode?.discount ?? mode?.percentage) !== 0) {
        return {
          ...defaultMode,
          price_col_prev: mode.price_col,
          price_usd_prev: mode.price_usd,
          price_col: mode.price_col * (1 - parseFloat(mode?.discount ?? mode?.percentage)),
          price_usd: mode.price_usd * (1 - parseFloat(mode?.discount ?? mode?.percentage)),
          discount: mode?.discount ?? mode?.percentage
        };
      } else {
        return {
          ...defaultMode
        };
      }
    });
  }

  return prices.sort(compare);
};

export const isMobileMatchMedia = () => window.matchMedia('(max-width: 991px)');

export const getNavbarHeight = () => {
  const NAVBAR_HEIGHT_WEB = 114;
  const NAVBAR_HEIGHT_MOVIL = 71;
  const isMovil = isMobileMatchMedia().matches;
  return isMovil ? NAVBAR_HEIGHT_MOVIL : NAVBAR_HEIGHT_WEB;
  // Problemas al utilizar el tamaño dinámico:
  // - Al iniciar el renderizado todos los elementos, el Navbar tiene un height mayor al que finalmente tendrá
  // - La actualización del height del Navbar se realiza hasta el renderizado de las páginas (screen)
  // - Si el Navbar está colapsado, al expandirse se cambia su height pero al colapsar nuevamente tarda en actualizarse esta propiedad
  // por lo tanto, se opta por definir los tamaños fijos.
  // return document.querySelector('#lt-navbar')?.offsetHeight || 0;  // Includes padding, scrollBar an borders of the div
};

/**
 * `window.scrollTo()` hacia el elemento referenciado, inmediatamente después del `Navbar`
 */
export const scrollToSectionWithOffset = (el) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -1 * getNavbarHeight();
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};
