import '../../../Generals/Styles/Skeletons.css';

import styles from './InputTextSKT.module.css';

const InputTextSKT = ({ className }) => {
  return (
    <div
      className={`${className} ${styles.border} ${styles.borderRadius}  skeleton`}
      style={{ border: '1px solid transparent' }}>
      <p style={{ visibility: 'hidden' }}>InputTextSKT</p>
    </div>
  );
};

export default InputTextSKT;
