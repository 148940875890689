import React from 'react';
import './Spinner.css';

const Spinner = ({ backgroundColor = 'black', className }) => {
  return (
    <div className={`lds-ellipsis ${className}`}>
      {Array.from({ length: 4 }, (_, i) => (
        <div key={i} style={{ backgroundColor }}></div>
      ))}
    </div>
  );
};

export default Spinner;
