import Spinner from '../../Atoms/Spinner/Spinner';

const CenteredSpinner = ({ backgroundColor }) => {
  return (
    <div className="w-100 text-center">
      <Spinner backgroundColor={backgroundColor} />
    </div>
  );
};

export default CenteredSpinner;
