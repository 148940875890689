import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../../Domain/Redux/Actions/Actions/ActionLenguageNavigator';

function languageSelected(languageList, languageNavigatorOnly = false) {
  let storedLanguage = localStorage.getItem('language_');

  if (
    !['undefined', 'null'].includes(storedLanguage) &&
    !(storedLanguage == null || storedLanguage == undefined || languageNavigatorOnly == true)
  ) {
    return JSON.parse(storedLanguage);
  }

  let navigatorLanguage = navigator.language.includes('-')
    ? navigator.language.slice(0, navigator.language.indexOf('-'))
    : navigator.language;

  const isLanguageValid = languageList?.some((item) => {
    return item.description?.toLowerCase() === navigatorLanguage;
  });
  navigatorLanguage = !isLanguageValid
    ? languageList?.find((item) => item.description === 'En')
    : languageList?.find((item) => item.description?.toLowerCase() === navigatorLanguage);

  return navigatorLanguage;
}

const useLanguageMonitor = () => {
  const languageList = useSelector((state) => state?.languageTraslate?.languageList);
  const language = useSelector((state) => state?.languageTraslate?.language);
  const [browserLanguage, setBrowserLanguage] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const handleLanguageChange = () => {
      setBrowserLanguage(navigator.language);
    };
    window.addEventListener('languagechange', handleLanguageChange);

    return () => {
      window.removeEventListener('languagechange', handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    if (!languageList?.length) {
      // dispatch(
      //   setLanguage({
      //     id: 3,
      //     name: 'Ingles',
      //     description: 'En',
      //     value: 3
      //   }),
      //   []
      // );
      return;
    }

    // check if the url has a setted language
    let base = window.location.pathname?.split('/');
    let selectedLanguage = base?.[1];
    const findLanguage = languageList?.filter(
      (info) => info?.description?.toLowerCase() === selectedLanguage
    );
    if (findLanguage?.length > 0) {
      selectedLanguage = findLanguage[0];
    } else {
      selectedLanguage = languageSelected(languageList);
    }
    localStorage.setItem('language_', JSON.stringify(selectedLanguage));
    dispatch(setLanguage(selectedLanguage));
  }, [languageList]);

  useEffect(() => {
    if (languageList?.length == 0) {
      return undefined;
    }

    const selectedLanguage = languageSelected(languageList, true);
    dispatch(setLanguage(selectedLanguage));
    localStorage.setItem('language_', JSON.stringify(selectedLanguage));
  }, [browserLanguage]);

  useEffect(() => {
    if (!languageList?.length) {
      return undefined;
    }

    localStorage.setItem('language_', JSON.stringify(language));
  }, [language]);
};

export default useLanguageMonitor;
