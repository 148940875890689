import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import HomeScreen from './Components/Pages/HomeScreen/HomeScreen';
// import LandingSection from './Components/Molecules/LandingSection/LandingSection';
import LiteThinkingScreen from './Components/Pages/LiteThinkingScreen/LiteThinkingScreen';

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
